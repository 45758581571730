import { useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

const Page = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 5000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  return (
    <>
      <div className="home">
        <div className="homeCont">
          <div className="introduction introductionXL">
            <h1>Passion For Building</h1>
          </div>
          <div className="mainHome">
            <div className="title">
              <h2>Discover TAXUS Timmerwerken</h2>
              <h3>Please let us explain our strengths</h3>
            </div>
            <div ref={sliderRef} className="keen-slider">
              <div className="keen-slider__slide number-slide1">
                <div className="slideCont">
                  <img src="../img/card1.png" alt="" />
                  <div className="rightCard">
                    <h2>Speed and flexibility</h2>
                    <p>
                      Taxus Timmerwerken is aware that a renovation in or around
                      the house is a major infringement of the privacy of the
                      residents.It is nice if the work...
                    </p>
                    <a href="/more?name=Speed and flexibility">
                      <button>Read more</button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="keen-slider__slide number-slide2">
                <div className="slideCont">
                  <img src="../img/card2.png" alt="" />
                  <div className="rightCard">
                    <h2>Professionality</h2>
                    <p>
                      Because the founder of Taxus Timmerwerken is an
                      architectural engineer, there is no lack of knowledge of
                      building.In addition, Taxus Timmerwerken has a good...
                    </p>
                    <a href="/more?name=Professionality">
                      <button>Read more</button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="keen-slider__slide number-slide3">
                <div className="slideCont">
                  <img src="../img/card3.png" alt="" />
                  <div className="rightCard">
                    <h2>Personality</h2>
                    <p>
                      Taxus Timmerwerken has a passion for building and Taxus
                      Timmerwerken also wants the product delivered to be a
                      unique product, specifically for the customer.Taxus...
                    </p>
                    <a href="/more?name=Personality">
                      <button>Read more</button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="keen-slider__slide number-slide4">
                <div className="slideCont">
                  <img src="../img/card4.png" alt="" />
                  <div className="rightCard">
                    <h2>Trustworthy</h2>
                    <p>
                      Delivering reliable work at fair prices is self -evident
                      for Taxus carpentry.We have a passion for building and do
                      not deliver half a job.In addition, we have the knowledge
                      and craftsmanship to deliver a reliable product.Clear
                      agreements...
                    </p>
                    <a href="/more?name=Trustworthy">
                      <button>Read more</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {loaded && instanceRef.current && (
              <div className="dots">
                {[
                  ...Array(
                    instanceRef.current.track.details.slides.length
                  ).keys(),
                ].map((idx) => {
                  return (
                    <button
                      key={idx}
                      onClick={() => {
                        instanceRef.current?.moveToIdx(idx);
                      }}
                      className={
                        "dot" + (currentSlide === idx ? " active" : "")
                      }
                    ></button>
                  );
                })}
              </div>
            )}
          </div>
          <div className="introduction introductionXL">
            <h1>Passion For Building</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;
