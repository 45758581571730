import React from "react";
import Title from "../components/Title";

const Diensten = () => {
  return (
    <div className="diensten">
      <Title title="Services" />
      <div className="main">
        <h2>
          The specialty of Taxus Timmerwerken can be subdivided into 4 aspects.
        </h2>
        <h2 className="blueH2">New construction</h2>
        <p>
          Build something completely new from nothing.We can guide you From the
          moment you have a plan that you want to reality to make.This can be as
          crazy as you want and Taxus carpentry will be indicate whether and how
          this is feasible.The collaboration between Creativity, quality and
          costs is something Taxus Timmerwerken has to deal with daily.In
          addition, the Netherlands has several regulations that must be taken
          into account at New construction projects.You don't have to scare
          this.Yew Carpentry can take care of the design, the drawing, the
          building application and the implementation.
          <br />
          <br />
          Think of a house, home office, tree house/house, Garden house, barn,
          outside stay, garden room, conservatory, etc.
        </p>
        <h2 className="blueH2">Conversion</h2>
        <p>
          Adjust something existing (renovate) to something new.You are just in
          possesses a new home or a building and want to make the layout like
          this So that it fully matches your situation.Taxus carpentry can do
          this take care of you completely.
          <br />
          <br />
          Think of an attic renovation, new layout of the house, dormer window,
          kitchen, fitted wardrobes, etc.
        </p>
        <h2 className="blueH2">Renovation</h2>
        <p>
          Modify/replace something in an existing situation with a new one
          variant. There are different materials in and around your home or
          building processed, each of which has its own properties, with the
          corresponding associated maintenance standards and service life.
          Maintenance is great importance and ensures that no replacement is
          necessary. Should this unexpectedly happen, Taxus Timmerwerken will
          help you
          <br />
          Taxus Timmerwerken will therefore look into why there is a part that
          needs replacement and then make sure it's new part is mounted
          correctly, so that any error will not occur again.
          <br />
          <br />
          Think of new frames, wood rot repair/replacement, doors, kitchen,
          walls, ceilings, post-insulating, energy-efficient construction, etc.
        </p>
        <h2 className="blueH2">Restoration</h2>
        <p>
          Adjust/replace something in an existing situation and in the old style
          return.Are you in possession of a home, building which Originally
          built before the war?Then you will undoubtedly enjoy certain
          characteristic elements that are incorporated into construction are.In
          general, the quality of the wood is from before The war very
          good.However, it does not have eternal life and is maintenance of
          great importance.If parts are ready for it replacement, then of course
          you want it to fit in with the home or building And also meets the
          current quality.This requires craftsmanship And creativity.Taxus
          carpentry can offer you this.
          <br />
          <br />
          Think of the restoration of sliding windows, room ensuite doors,
          frames, stairs, doors, ceilings, walls, kitchens, monuments or
          Characteristic homes from a certain season (and after-insulation or
          the conversion of an old farm into a farmhouse.)
        </p>
      </div>
    </div>
  );
};

export default Diensten;
