import React from "react";
import Title from "../components/Title";

const notFound = () => {
  return (
    <div className="notFound">
      <Title title="page not found" />
      <div className="main">
        <h2>Oops, deze pagina bestaat niet :\</h2>
        <span className="error">404</span>
      </div>
    </div>
  );
};

export default notFound;
