import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dutch from "../img/netherlands.png";
import portugal from "../img/portugal.png";
import english from "../img/united-kingdom.png";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClick = () => {
    setMenuOpen(!menuOpen ? true : false);
  };

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  if (screenSize.width >= 800 && menuOpen) {
    setMenuOpen(false);
  }

  const path = window.location.pathname;

  const [home, setHome] = useState("");
  const [contact, setContact] = useState("");
  const [diensten, setDiensten] = useState("");
  const [faq, setFaq] = useState("");
  const [offerte, setOfferte] = useState("");
  const [over, setOver] = useState("");
  const [referenties, setReferenties] = useState("");
  const [projecten, setProjecten] = useState("");

  const handleCurrent = (e) => {
    setHome("");
    setContact("");
    setDiensten("");
    setFaq("");
    setOfferte("");
    setOver("");
    setReferenties("");
    setProjecten("");
    e("current");
  };
  useEffect(() => {
    if (path === "/") {
      handleCurrent(setHome);
    }
    if (path === "/contact") {
      handleCurrent(setContact);
    }
    if (path === "/diensten") {
      handleCurrent(setDiensten);
    }
    if (path === "/faq") {
      handleCurrent(setFaq);
    }
    if (path === "/offerte") {
      handleCurrent(setOfferte);
    }
    if (path === "/over") {
      handleCurrent(setOver);
    }
    if (path === "/referenties") {
      handleCurrent(setReferenties);
    }
    if (path === "/projecten") {
      handleCurrent(setProjecten);
    }
  });
  return (
    <div className="headerReplace">
      <header className="header">
        <div className="headerCont">
          <a href="/">
            <img
              class="logo"
              src="https://www.taxustimmerwerken.nl/img/logo.png"
              alt="Taxus Timmerwerken"
            />
          </a>
          <button
            onClick={() => {
              handleClick();
            }}
            className={screenSize.width <= 800 ? "menuButton" : "none"}
          >
            Menu{" "}
            <i
              class={
                menuOpen
                  ? "fa-solid fa-caret-down caret-turn"
                  : "fa-solid fa-caret-down"
              }
            ></i>
          </button>
          <ul
            className={
              screenSize.width <= 800
                ? menuOpen
                  ? "mobileMenu open"
                  : "mobileMenu"
                : "desktopMenu"
            }
          >
            <li className={home}>
              <Link
                onClick={() => {
                  handleCurrent(setHome);
                  window.scrollTo(0, 0);

                  handleClick();
                }}
                to="/"
              >
                Home
              </Link>
            </li>
            <li className={contact}>
              <Link
                onClick={() => {
                  handleCurrent(setContact);
                  window.scrollTo(0, 0);

                  handleClick();
                }}
                to="/contact"
              >
                Contact
              </Link>
            </li>
            <li className={diensten}>
              <Link
                onClick={() => {
                  handleCurrent(setDiensten);
                  window.scrollTo(0, 0);

                  handleClick();
                }}
                to="/diensten"
              >
                Diensten
              </Link>
            </li>
            <li className={faq}>
              <Link
                onClick={() => {
                  handleCurrent(setFaq);
                  window.scrollTo(0, 0);

                  handleClick();
                }}
                to="/faq"
              >
                FAQ
              </Link>
            </li>
            <li className={offerte}>
              <Link
                onClick={() => {
                  handleCurrent(setOfferte);
                  window.scrollTo(0, 0);

                  handleClick();
                }}
                to="/offerte"
              >
                Offerte
              </Link>
            </li>
            <li className={over}>
              <Link
                onClick={() => {
                  handleCurrent(setOver);
                  window.scrollTo(0, 0);

                  handleClick();
                }}
                to="/over"
              >
                Over Ons
              </Link>
            </li>
            <li className={referenties}>
              <Link
                onClick={() => {
                  handleCurrent(setReferenties);
                  window.scrollTo(0, 0);

                  handleClick();
                }}
                to="/referenties"
              >
                Referenties
              </Link>
            </li>
            <li className={projecten}>
              <Link
                onClick={() => {
                  handleCurrent(setProjecten);
                  window.scrollTo(0, 0);

                  handleClick();
                }}
                to="/projecten"
              >
                Projecten
              </Link>
            </li>
            <li>
              <ul className="icons">
                <li>
                  <Link to="https://taxustimmerwerken.nl">
                    <img src={dutch} alt="" />
                  </Link>
                </li>
                {/* <li>
                  <Link to="http://10.0.0.154:3000">
                    <img src={portugal} alt="" />
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="http://eng.localhost:3000">
                    <img src={english} alt="" />
                  </Link>
                </li> */}
                <li>
                  <Link to="https://eng.taxustimmerwerken.nl">
                    <img src={english} alt="" />
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
};

export default Header;
