import React from "react";
import Title from "../components/Title";

const Referenties = () => {
  return (
    <div className="referenties">
      <Title title="References" />
      <div className="main">
        <h2 className="blueH2">Nice result</h2>
        <p>
          You recently carried out work for us, a basement stairs Made safe
          again and replaced a wooden front to Houtrot Save.
          <br />
          <br />
          Everything is extremely professional and done according to agreement
          and we are completely satisfied.
          <br />
          We will certainly approach you again.
          <br />
          <br />
          Yours sincerely,
          <br />
          <br />
          Chris & Carol
        </p>

        <h2 className="blueH2">Thanks for your work!</h2>
        <p>
          Taxus Timmerwerken has mounted cupboards in the storage room and my
          company car decorated. And all with 100 % satisfaction.I I am very
          satisfied with the work that Taxus Timmerwerken has delivered. I
          recommend Taxus Timmerwerken everyone.Thank you Jouke.When I again I
          have something like that, then I will definitely call you.
          <br />
          <br />
          regards,
          <br />
          <br />
          Duran
        </p>

        <h2 className="blueH2">Beautiful garage</h2>
        <p>
          We are very satisfied with the way Jouke worked to build a new wooden
          garage.Everything went exactly according to Planning and
          agreements.Jouke worked very hard and left everything Always neat
          behind.He thought creatively, like the recovery /from garage doors.We
          are very happy with the result.
          <br />
          <br />
          Felice and Arie
        </p>

        <h2 className="blueH2">Nice work</h2>
        <p class="noLine">
          Taxus Timmerwerken is for us:
          <br />
          <br />
        </p>
        <ul>
          <li>Clear and honest quotations.</li>
          <li>
            A company that thinks along and does not purely carry out without
            think.
          </li>
          <li>A company that fulfills agreements.</li>
          <li>
            A company that works calmly and clean and takes into account one
            busy family with young children.
          </li>
        </ul>
        <p>
          <br />
          <br />
          That is why we are open to Potential customers of Taxus To speak to
          carpentry and even the work performed with us at home.
          <br />
          <br />
          Was signed Sjoerd
        </p>

        <h2 className="blueH2">A real professional!</h2>
        <p>
          Jouke is a real professional and a pleasant person who thinks along
          with clear proposals and with whom we have been able to do well
          agreements to make.He has a beautiful raised hardwood for us terrace
          flonder designed and made, with partial roof and Storage below.In
          addition, also a hardwood garden bench.Jouke is one hard worker who is
          only satisfied when it is completely good and offers one Great
          price-quality ratio.
          <br />
          <br />
          In short: we would definitely switch it on again and advise him on
          everyone On!
          <br />
          <br />
          Peter & Marijke
        </p>

        <h2 className="blueH2">Professional company</h2>
        <p>
          Taxus has carried out a number of assignments for us (including door
          built and bicycle shed built).
          <br />
          <br />
          These are very professional, within budget and time schedule executed.
          <br />
          Jouke has shown to know what to do and have fun in it to have a
          profession.
          <br />
          I can recommend Taxus Timmerwerken and Jouke Rypkema to everyone.
          <br />
          <br />
          Roderic
        </p>

        <h2 className="blueH2">A pleasure to work with</h2>
        <p>
          Jouke helped us with the transformation of an old construction site to
          our Pipowagen.
          <br />
          <br />
          It always remains calm, is very competent, is open to Suggestions, if
          necessary, can improvise well to the right solution To be found and is
          a hard worker.
          <br />
          <br />
          It was a pleasure to work with him.
          <br />
          <br />
          Frans & Anne-Mieke
        </p>

        <h2 className="blueH2">Beautiful barn annex country retreat</h2>
        <p>
          We took Jouke because we from the first meeting had a good click.His
          love for the craft radiates from it. Within the estimated time and
          within budget he has the job perfect delivered.He takes care of his
          things and his environment That makes it nice to work with him.Jouke
          now has one Continued assignment already delivered just as nicely.As
          far as we are concerned, one golden relationship that we can hopefully
          make more often more often.
          <br />
          <br />
          In the meantime we enjoy a beautiful barn annex country retreat in the
          garden!
          <br />
          <br />
          Michiel & Carien
        </p>

        <h2 className="blueH2">
          We are very pleased with the quality of Taxus Timmerwerken
        </h2>
        <p>
          Jouke has isolated our entire first floor and of new ones walls and
          electricity.In addition, he has a beautiful cupboard made to measure
          with panel doors.We are very pleased with the Quality of Jouke's
          work.In addition, it is reliable, a good one Planner and a good
          communicator.At Jouke we knew exactly where we were ready.Moreover,
          Jouke is a very pleasant cheerful guy that you like to walk through
          your house!
          <br />
          <br />
          Miriam & Dirk-Jan
        </p>

        <h2 className="blueH2">Very satisfied with the work delivered</h2>
        <p>
          We had Taxus do the post-insulation of our sloping roofs Carpentry
          from Zeist.
          <br />
          <br />
          We are very satisfied with the work delivered, in particular the
          robustness, accuracy and the pleasant intercourse.
          <br />
          <br />
          Jenniskens
        </p>

        <h2 className="blueH2">Beautiful kitchen!</h2>
        <p>
          We had our kitchen wall covered with OSB wood, to make it one Nice
          industrial look.Because we live in a house that more than 100 years
          old, everything is crooked, a very difficult job to cover.Jouke has
          done everything perfectly, everything fits exactly and is beautifully
          finished.He is a real professional who knows very well what he does.He
          is not satisfied with details that are incorrect, everything Must be
          perfect, hence the beautiful finish.We have him left with a very
          comfortable heart in the house while we ourselves were the work.He
          thinks in solutions, thinks along, also within one certain budget.A
          reliable professional that we will definitely be next time Switch on
          again if we want to have something beautiful made in the house.We
          still enjoy our beautiful kitchen every day!
          <br />
          <br />
          Ilvy & Sander
        </p>

        <h2 className="blueH2">A real professional</h2>
        <p>
          Jouke has succeeded in all the crooked, crooked & squeaking windows
          and doors To get back from my 1930s house.Where I myself Was afraid
          that I would have to purchase a new back door, it was A.G.V. A well
          -placed three -point closure is not even necessary.The quotation was
          also worked out in much detail so that I was in advance Well knew what
          I could and could not expect.The assignment is to all satisfaction!
          <br />
          <br />
          Sander
        </p>

        <h2 className="blueH2">Become a beautiful attic!</h2>
        <p>
          Jouke has converted our attic into rooms with walls, finishing and
          electricity.The quotation is very clearly prepared, you know what you
          get and you can see what the costs are if you are for different
          options Chooses.The work is neat and professional and goes like you it
          asks.In addition, all agreements have been met, which is also pleasant
          With this type of job.And reliable professional.
          <br />
          <br />
          Niels
        </p>
      </div>
    </div>
  );
};

export default Referenties;
