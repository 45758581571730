import React, { useState } from "react";
// import { Link } from "react-router-dom";

const Footer = () => {
  const currYear = new Date().getFullYear();

  const [diensten, setDiensten] = useState("");
  const [over, setOver] = useState("");

  const handleCurrent = (e) => {
    setDiensten("");
    setOver("");
    e("current");
    // window.scrollTo(0, 0);
  };
  return (
    <div className="footer">
      <div className="txtWrapper">
        <div className="txt">
          <h3>Wie zijn wij?</h3>
          <p>
            TAXUS Timmerwerken is opgericht op 1 januari 2010 door Jouke
            Rypkema. Jouke Rypkema is na zijn MTS en HTS bouwkunde opleiding
            succesvol afgerond te hebben, in 2002 zijn werkende carrière
            begonnen. Hij werkte bij een landelijk dekkend montagebedrijf als
            calculator en werkvoorbereider en werkte zich snel op als succesvol
            projectleider. Tijdens deze baan heeft Jouke in Zambia op
            vrijwillige basis een school gebouwd onder zeer primitieve
            omstandigheden.
          </p>
          <a
            href="/over"
            onClick={() => {
              handleCurrent(setOver);
            }}
          >
            <button>Lees verder...</button>
          </a>
        </div>
        <div className="txt">
          <h3>Wat doen wij?</h3>
          <p>TAXUS Timmerwerken heeft vier specialisaties.</p>
          <ul>
            <li>Bouw</li>
            <li>Verbouw</li>
            <li>Renovatie</li>
            <li>Restauratie</li>
          </ul>
          <a
            href="/diensten"
            onClick={() => {
              handleCurrent(setDiensten);
            }}
          >
            <button>Meer weten? Klik hier</button>
          </a>
        </div>
      </div>
      <div className="copyright">
        <p>
          Copyright &copy; 2010-{currYear} TAXUS Timmerwerken - Alle rechten
          voorbehouden.
        </p>
      </div>
    </div>
  );
};

export default Footer;
