import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Title from "../components/Title";

const Verder = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const name = urlParams.get("name");

  const [content, setContent] = useState("");
  const [content2, setContent2] = useState("");

  useEffect(() => {
    if (name === "Speed and flexibility") {
      setContent(
        "Taxus Timmerwerken is aware that a renovation in or around the house is a major infringement of the privacy of the residents.It is nice if the work can be completed properly and quickly so that the customer can enjoy his new product.To ensure that the entire building process runs smoothly and well -arranged, good work preparation, compact planning and clean workplace are of great importance.This is feasible because Taxus Timmerwerken has knowledge, craftsmanship, a professional network, great flexibility, speed and good communication skills."
      );
    }
    if (name === "Professionality") {
      setContent(
        "Because the founder of Taxus Timmerwerken is an architectural engineer, there is no lack of knowledge of building.In addition, Taxus Timmerwerken has a good and familiar network, including architectural firms, engineering firms, consultancy firms, suppliers of materials and craftsmen.This ensures that a continuous flow of development takes place.Not only is this a big advantage for the quality and speed, but this allows Taxus carpentry to give honest advice about what is or is not possible.Because of this network, taking care of drawings with the corresponding permit applications is no problem.This makes the lines short, which accelerates the construction process."
      );
    }
    if (name === "Personality") {
      setContent(
        "Taxus Timmerwerken has a passion for building and Taxus Timmerwerken also wants the product delivered to be a unique product, specifically for the customer.Taxus Timmerwerken therefore always comes first to go through the wishes of the customer and to view the situation.For example, a personal and appropriate solution is sought, which is translated into an offer/quotation."
      );
    }
    if (name === "Trustworthy") {
      setContent(
        "Delivering reliable work at fair prices is self -evident for Taxus carpentry.We have a passion for building and do not deliver half a job.In addition, we have the knowledge and craftsmanship to deliver a reliable product.Clear agreements in advance and a solid guarantee for Taxus Timmerwerken are obvious."
      );
      setContent2(
        "Taxus Timmerwerken works with good, trusted and regular suppliers and has been supplying its customers with the right materials at the right time for several years.Because of these relationships, the suppliers have become a real extension of Taxus carpentry.Partly because of this, fair and market are a fact that conforming prices are a fact."
      );
    }
  });

  return (
    <div className="verder">
      <Title title={name} />
      <div className="main">
        <p>
          {content}
          <br />
          <br />
          {content2 ? content2 : null}
        </p>
      </div>
    </div>
  );
};

export default Verder;
