import React from "react";
import Title from "../components/Title";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div className="contact">
      <Title title="Contact" />
      <div className="main">
        <h2 className="blueH2">TAXUS Timmerwerken</h2>
        <ul>
          <li>Klein Heidestein 2c</li>
          <li>3971 ND Driebergen Rijsenburg</li>
          <br />
          <li>030 274 6824</li>
          <li>
            <Link to="mailto:info@taxustimmerwerken.nl">
              info@taxustimmerwerken.nl
            </Link>
          </li>
          <li>
            <Link to="https://www.taxustimmerwerken.nl">
              www.taxustimmerwerken.nl
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Contact;
