import React from "react";
import Title from "../components/Title";

const Diensten = () => {
  return (
    <div className="diensten">
      <Title title="diensten" />
      <div className="main">
        <h2>
          Het specialisme van TAXUS Timmerwerken is onder te verdelen in 4
          aspecten.
        </h2>
        <h2 className="blueH2">Nieuwbouw</h2>
        <p>
          Iets vanuit niets helemaal nieuw opbouwen. Wij kunnen u begeleiden
          vanaf het moment dat u een plan heeft dat u tot werkelijkheid wilt
          maken. Dit kan zo gek zijn als u wilt en Taxus Timmerwerken zal
          aangeven of en hoe dit haalbaar is. De samenwerking tussen
          creativiteit, kwaliteit en kosten is iets waar Taxus Timmerwerken
          dagelijks mee te maken heeft. Daarnaast kent Nederland verschillende
          regelgevingen waar rekening mee gehouden dient te worden bij
          nieuwbouwprojecten. Dit hoeft u niet af te schrikken. Taxus
          Timmerwerken kan voor u het ontwerp verzorgen, het tekenwerk, de
          bouwaanvraag en de uitvoering.
          <br />
          <br />
          Denk hierbij aan een woonhuis, kantoor aan huis, Boomhut/huis,
          tuinhuis, schuur, buiten verblijf, tuinkamer, serre, etc.
        </p>
        <h2 className="blueH2">Verbouw</h2>
        <p>
          Iets bestaands aanpassen (verbouwen) tot iets nieuws. U bent net in
          bezit van een nieuwe woning of een pand en wilt de indeling zo maken
          zodat deze volledig past bij uw situatie. Taxus Timmerwerken kan dit
          geheel voor u verzorgen.
          <br />
          <br />
          Denk hierbij aan een zolderverbouwing, nieuwe indeling van het huis,
          dakkapel, keuken, inbouwkasten, etc.
        </p>
        <h2 className="blueH2">Renovatie</h2>
        <p>
          Iets in een bestaande situatie aanpassen/vervangen door een nieuwe
          variant. In en rond uw woning of pand zijn verschillende materialen
          verwerkt, welke ieder zijn eigen eigenschappen kent, met de daarbij
          horende onderhoudsnormen en levensduur. Het onderhoud is van groot
          belang en zorgt er voor dat er niet vervangen hoeft te worden. Mocht
          dit onverhoopt toch een keer voorkomen dan kan Taxus Timmerwerken u
          uit de br /and helpen. Taxus Timmerwerken zal dan ook kijken hoe het
          komt dat er een onderdeel vervangen dient te worden en er dan voor
          zorgen dat het nieuwe onderdeel op de juiste wijze gemonteerd wordt,
          zodat een eventuele fout er niet nogmaals in komt.
          <br />
          <br />
          Denk hierbij aan nieuwe kozijnen, houtrot reparatie/vervanging,
          deuren, keuken, wanden, plafonds, na-isoleren, energie zuinig bouwen,
          etc.
        </p>
        <h2 className="blueH2">Restauratie</h2>
        <p>
          Iets in een bestaande situatie aanpassen/vervangen en in de oude stijl
          terug br /engen. Bent u in het bezit van een woning, pand welke
          oorspronkelijk gebouwd is voor de oorlog? Dan zult u ongetwijfeld van
          bepaalde karakteristieke elementen genieten welke in de bouw verwerkt
          zijn. Over het algemeen genomen is de kwaliteit van het hout van voor
          de oorlog erg goed. Echter heeft het niet het eeuwige leven en is
          onderhoud van groot belang. Mochten er onderdelen toe zijn aan
          vervanging, dan wilt u uiteraard dat het past in de woning of het pand
          en daarnaast voldoet aan de huidige kwaliteit. Dit vergt vakmanschap
          en creativiteit. Taxus Timmerwerken kan u dit bieden.
          <br />
          <br />
          Denk hierbij aan restauratie van schuiframen, kamer ensuite deuren,
          kozijnen, trappen, deuren, plafonds, wanden, keukens, monumenten of
          karakteristieke woningen uit een bepaald jaargetij (en aan na-isoleren
          of het ombouwen van een oude boerderij tot woonboerderij.)
        </p>
      </div>
    </div>
  );
};

export default Diensten;
