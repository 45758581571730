import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AppEng from "./AppEng";

const root = ReactDOM.createRoot(document.getElementById("root"));

const url = window.location.hostname;

if (url === "taxuscarpintaria.pt" || url === "www.taxuscarpintaria.pt") {
  window.location.replace("https://eng.taxustimmerwerken.nl");
}

if (
  url === "eng.taxustimmerwerken.nl" ||
  url === "www.eng.taxustimmerwerken.nl"
) {
  root.render(
    <React.StrictMode>
      <AppEng />
    </React.StrictMode>
  );
} else {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
