import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/styles.scss";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Offerte from "./pages/Offerte";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Over from "./pages/Over";
import Referenties from "./pages/Referenties";
import Diensten from "./pages/Diensten";
import Faq from "./pages/Faq";
import Projecten from "./pages/Projecten";
import ProjectenPics from "./pages/ProjectenPics";
import Verder from "./pages/Verder";
import NotFound from "./pages/NotFound";
import { Helmet } from "react-helmet";

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>Passie Voor Bouwen | TAXUS Timmerwerken</title>
        <meta
          name="description"
          content="TAXUS Timmerwerken is opgericht op 1 januari 2010 door Jouke Rypkema. Jouke Rypkema is na zijn MTS en HTS bouwkunde opleiding succesvol afgerond te hebben, in 2002 zijn werkende carrière begonnen. Hij werkte bij een landelijk dekkend montagebedrijf als calculator en werkvoorbereider en werkte zich snel op als succesvol projectleider. Tijdens deze baan heeft Jouke in Zambia op vrijwillige basis een school gebouwd onder zeer primitieve omstandigheden."
        />
      </Helmet>
      <Header />
      <Routes>
        <Route path="*" element={<NotFound />}></Route>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="diensten" element={<Diensten />} />
          <Route path="faq" element={<Faq />} />
          <Route path="offerte" element={<Offerte />} />
          <Route path="over" element={<Over />} />
          <Route path="referenties" element={<Referenties />} />
          <Route path="projecten" element={<Projecten />} />
          <Route path="closeups" element={<ProjectenPics />} />
          <Route path="verder" element={<Verder />} />
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
