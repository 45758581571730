import React from "react";
import Title from "../components/Title";

const Over = () => {
  return (
    <div className="over">
      <Title title="About Us" />
      <div className="main">
        <h2 className="blueH2">TAXUS Timmerwerken</h2>
        <p>
          Taxus Timmerwerken was founded on January 1, 2010 by Jouke Rypkema.
          Jouke Rypkema is successful after his MTS and HTS Architecture
          Training having completed his working career in 2002.He worked at a
          nationwide assembly company as a calculator and Work planner and
          quickly worked as a successful project manager. During this job, Jouke
          has a voluntary basis in Zambia School built under very primitive
          conditions.
        </p>
        <br />

        <p>
          Taxus Timmerwerken has a good and familiar network consisting of other
          from fellow professionals, architectural firms, engineering firms,
          Consultants and suppliers of materials.This ensures that there A
          continuous flow of development takes place, which is directly Good
          gets the end result.
        </p>
        <h2 className="blueH2">Jouke</h2>
        <p>
          In 2009 Jouke and his family left for Norway for 1 year learn the real
          carpentry.After this and previous experiences, the Time ripe to start
          Taxus Timmerwerken.Taxus carpentry is A well -running company that
          mainly focuses on new construction, Renovation, renovation and
          restoration work.A company that Experience, knowledge and especially
          passion for work.
        </p>
      </div>
    </div>
  );
};

export default Over;
