import React from "react";
import Title from "../components/Title";

const Over = () => {
  return (
    <div className="over">
      <Title title="over ons" />
      <div className="main">
        <h2 className="blueH2">TAXUS Timmerwerken</h2>
        <p>
          TAXUS Timmerwerken is opgericht op 1 januari 2010 door Jouke Rypkema.
          Jouke Rypkema is na zijn MTS en HTS bouwkunde opleiding succesvol
          afgerond te hebben, in 2002 zijn werkende carrière begonnen. Hij
          werkte bij een landelijk dekkend montagebedrijf als calculator en
          werkvoorbereider en werkte zich snel op als succesvol projectleider.
          Tijdens deze baan heeft Jouke in Zambia op vrijwillige basis een
          school gebouwd onder zeer primitieve omstandigheden.
        </p>
        <br />

        <p>
          TAXUS Timmerwerken heeft een goed en vertrouwd netwerk bestaande onder
          andere uit collega vakmensen, architectenbureaus, ingenieursbureaus,
          adviesbureaus en leveranciers van materialen. Dit zorgt er voor dat er
          een continue stroom van ontwikkeling plaats vindt, wat direct ten
          goede komt aan het eindresultaat.
        </p>
        <h2 className="blueH2">Jouke</h2>
        <p>
          In 2009 is Jouke met zijn gezin 1 jaar naar Noorwegen vertrokken om
          het echte timmerwerk te leren. Na deze en vorige ervaringen was de
          tijd rijp om TAXUS Timmerwerken op te starten. TAXUS Timmerwerken is
          een goed lopend bedrijf dat zich voornamelijk richt op nieuwbouw,
          verbouw, renovatie en restauratie -werkzaamheden. Een bedrijf dat de
          ervaring, kennis en vooral passie heeft voor het werk.
        </p>
      </div>
    </div>
  );
};

export default Over;
