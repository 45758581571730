import React from "react";
import Title from "../components/Title";

const Faq = () => {
  return (
    <div className="faq">
      <Title title="FAQ" />
      <div className="main">
        <h2>
          Get an answer to the most by our customers and clients asked
          questions.
        </h2>
        <h2 className="blueH2">Court costs are calculated?</h2>
        <p>No.Taxus Timmerwerken does not charge call -out costs.</p>
        <h2 className="blueH2">There are costs associated with a quote?</h2>
        <p>
          No.Taxus Timmerwerken is happy to visit your wishes and possibilities
          to discuss and then make a quote for you.
        </p>
        <h2 className="blueH2">What does the warranty mean?</h2>
        <p>
          <var>~ Appendix General Warranty Statement</var>
        </p>
        <h2 className="blueH2">How can I give a reference?</h2>
        <p>
          After completing the project you can send a reference to{" "}
          <a href="mailto:referentie@taxustimmerwerken.nl.">
            referentie@taxustimmerwerken.nl.
          </a>
        </p>
        <h2 className="blueH2">How are I and Taxus carpentry insured?</h2>
        <p>
          Taxus Timmerwerken is in possession of one liability insurance and
          also a C.A.R.Insurance (Construction All Risks).
        </p>
        <h2 className="blueH2">
          Do you have another question?Please contact us.
        </h2>
        <p>
          <strong>Telephone:</strong> 030-2746824
        </p>
        <br />
        <p>
          <strong>E-mail:</strong>{" "}
          <a href="mailto:klantenservice@taxustimmerwerken.nl">
            klantenservice@taxustimmerwerken.nl
          </a>
        </p>
      </div>
    </div>
  );
};

export default Faq;
