import React from "react";
import Title from "../components/Title";
import { Link } from "react-router-dom";

const Projecten = () => {
  const images1 = ["1", "1.2", "1.3", "1.4", "1.5", "1.6", "1.7", "1.8", "1.9"];
  const images2 = ["2", "2.2", "2.3", "2.4", "2.5", "2.6", "2.7", "2.8"];
  const images3 = ["3"];
  const images4 = ["4", "4.2", "4.3"];
  const images5 = ["5", "5.2", "5.3", "5.4", "5.5", "5.6", "5.7"];
  const images6 = ["6", "6.2", "6.3", "6.4", "6.5", "6.6", "6.7", "6.8"];
  const images7 = ["7", "7.2", "7.3", "7.4", "7.5", "7.6", "7.7", "7.8"];
  const images8 = ["8", "8.2", "8.3", "8.4", "8.5", "8.6", "8.7", "8.8", "8.9"];
  const images9 = ["9", "9.2", "9.3"];
  const images10 = ["10", "10.2", "10.3"];

  return (
    <div className="projecten">
      <Title title="projecten" />
      <div className="main projecten">
        <div className="grid">
          <Link
            state={{ images: images1 }}
            to="/closeups?name=Rustieke eikenhouten trap"
          >
            <div className="items">
              <span className="shadow"></span>
              <img
                src="../img/1.jpg"
                alt="bouw project"
                title="Rustieke eikenhouten trap"
              />
            </div>
          </Link>
          <Link
            state={{ images: images2 }}
            to="/closeups?name=Kastenwand woonkamer"
          >
            <div className="items">
              <span className="shadow"></span>
              <img
                src="../img/2.jpg"
                alt="bouw project"
                title="Kastenwand woonkamer"
              />
            </div>
          </Link>
          <Link
            state={{ images: images3 }}
            to="/closeups?name=buitentrap met balkon en vlonder"
          >
            <div className="items">
              <span className="shadow"></span>
              <img
                src="../img/3.jpg"
                alt="bouw project"
                title="Buitentrap met balkon en vlonder"
              />
            </div>
          </Link>
          <Link
            state={{ images: images4 }}
            to="/closeups?name=tuinkamer / schuur"
          >
            <div className="items">
              <span className="shadow"></span>
              <img
                src="../img/4.jpg"
                alt="bouw project"
                title="Tuinkamer / schuur"
              />
            </div>
          </Link>
          <Link
            state={{ images: images5 }}
            to="/closeups?name=inbouwkast op maat"
          >
            <div className="items">
              <span className="shadow"></span>
              <img
                src="../img/5.jpg"
                alt="bouw project"
                title="Inbouwkast op maat"
              />
            </div>
          </Link>
          <Link
            state={{ images: images6 }}
            to="/closeups?name=uitbouw serre boerderij"
          >
            <div className="items">
              <span className="shadow"></span>
              <img
                src="../img/6.jpg"
                alt="bouw project"
                title="Uitbouw serre boerderij"
              />
            </div>
          </Link>
          <Link state={{ images: images7 }} to="/closeups?name=keuken op maat">
            <div className="items">
              <span className="shadow"></span>
              <img
                src="../img/7.jpg"
                alt="bouw project"
                title="Keuken op maat"
              />
            </div>
          </Link>
          <Link
            state={{ images: images8 }}
            to="/closeups?name=verbouwing boerderij"
          >
            <div className="items">
              <span className="shadow"></span>
              <img
                src="../img/8.jpg"
                alt="bouw project"
                title="Verbouwing boerderij"
              />
            </div>
          </Link>
          <Link state={{ images: images9 }} to="/closeups?name= bar meubel">
            <div className="items">
              <span className="shadow"></span>
              <img src="../img/9.jpg" alt="bouw project" title="Bar meubel" />
            </div>
          </Link>
          <Link
            state={{ images: images10 }}
            to="/closeups?name=verbouwing keuken"
          >
            <div className="items">
              <span className="shadow"></span>
              <img
                src="../img/10.jpg"
                alt="bouw project"
                title="Verbouwing keuken"
              />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Projecten;
