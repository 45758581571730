import React from "react";

const Title = (props) => {
  const title = props.title;
  return (
    <div className="introduction">
      <h1>{title}</h1>
    </div>
  );
};

export default Title;
