import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Title from "../components/Title";

const Verder = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const name = urlParams.get("name");

  const [content, setContent] = useState("");
  const [content2, setContent2] = useState("");

  useEffect(() => {
    if (name === "Snelheid en Flexibiliteit") {
      setContent(
        "TAXUS Timmerwerken is zich ervan bewust dat een verbouwing in of rond het huis een grote inbreuk is op de privacy van de bewoners. Het is prettig als de werkzaamheden goed en snel afgerond kunnen worden zodat de klant van zijn nieuwe product kan genieten. Om het gehele bouwproces goed en overzichtelijk te kunnen laten verlopen is een goede werkvoorbereiding, compacte planning en schone werkplek van groot belang. Dit is haalbaar doordat TAXUS Timmerwerken beschikt over kennis, vakmanschap, een professioneel netwerk, grote flexibiliteit, snelheid en goede communicatieve vaardigheden."
      );
    }
    if (name === "Professionaliteit") {
      setContent(
        "Doordat de oprichter van TAXUS Timmerwerken een bouwkundig ingenieur is, is er geen gebrek aan kennis van bouwen. Daarnaast heeft TAXUS Timmerwerken een goed en vertrouwd netwerk, bestaande onder andere uit architectenbureaus, ingenieursbureaus, adviesbureaus, leveranciers van materialen en vaklieden. Dit zorgt er voor dat er een continue stroom van ontwikkeling plaats vindt. Niet alleen is dit voor de kwaliteit en snelheid een groot voordeel, maar hierdoor kan TAXUS Timmerwerken een eerlijk advies geven over wat wel of wat niet kan. Door dit netwerk is het verzorgen van tekenwerk met de bijbehorende vergunningsaanvragen geen enkel probleem. Hierdoor zijn de lijnen kort, wat het bouwproces doet versnellen."
      );
    }
    if (name === "Persoonlijkheid") {
      setContent(
        "TAXUS Timmerwerken heeft een passie voor bouwen en daarnaast wil TAXUS Timmerwerken dat het geleverde product een uniek product is, specifiek voor de klant. Taxus Timmerwerken komt daarom altijd eerst langs om de wensen van de klant door te nemen en de situatie te bekijken. Zo wordt er een persoonlijke en passende oplossing gezocht, welke vertaald wordt in een aanbieding/offerte."
      );
    }
    if (name === "Betrouwbaar") {
      setContent(
        "Betrouwbaar werk afleveren tegen eerlijke prijzen is voor TAXUS Timmerwerken vanzelfsprekend. Wij hebben een passie voor bouwen en leveren geen half werk. Daarnaast hebben wij de kennis en het vakmanschap in huis om een betrouwbaar product af te leveren. Duidelijke afspraken vooraf en een degelijke garantie zijn voor Taxus Timmerwerken vanzelfsprekend."
      );
      setContent2(
        "TAXUS Timmerwerken werkt met goede, vertrouwde en vaste leveranciers en voorziet zijn klanten al meerdere jaren van de juiste materialen op het juiste moment. Door deze relaties zijn de leveranciers een echt verlengstuk van TAXUS Timmerwerken geworden. Mede hierdoor zijn eerlijke en markt conforme concurrerende prijzen een feit."
      );
    }
  });

  return (
    <div className="verder">
      <Title title={name} />
      <div className="main">
        <p>
          {content}
          <br />
          <br />
          {content2 ? content2 : null}
        </p>
      </div>
    </div>
  );
};

export default Verder;
