import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/styles.scss";
import Home from "./pagesEng/Home";
import Contact from "./pagesEng/Contact";
import Offerte from "./pagesEng/Offerte";
import Header from "./componentsEng/Header";
import Footer from "./componentsEng/Footer";
import Over from "./pagesEng/Over";
import Referenties from "./pagesEng/Referenties";
import Diensten from "./pagesEng/Diensten";
import Faq from "./pagesEng/Faq";
import Projecten from "./pagesEng/Projecten";
import ProjectenPics from "./pagesEng/ProjectenPics";
import Verder from "./pagesEng/Verder";
import NotFound from "./pagesEng/NotFound";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="*" element={<NotFound />}></Route>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="services" element={<Diensten />} />
          <Route path="faq" element={<Faq />} />
          <Route path="quotation" element={<Offerte />} />
          <Route path="about" element={<Over />} />
          <Route path="references" element={<Referenties />} />
          <Route path="projects" element={<Projecten />} />
          <Route path="closeups" element={<ProjectenPics />} />
          <Route path="more" element={<Verder />} />
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
