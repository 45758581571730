import React, { useEffect } from "react";
import Title from "../components/Title";
import { useLocation } from "react-router-dom";

const ProjectenPics = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const name = urlParams.get("name");

  const location = useLocation();
  const { images } = location.state;
  return (
    <div className="projectenPics">
      <Title title={name} />
      <div className="mainPart">
        <div className="mainContent">
          <div className="grid">
            {images.map((each, index) => (
              <div key={index} className="each-image">
                <img src={"../../img/projecten/" + each + ".jpg"} alt={name} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectenPics;
