import React from "react";
import Title from "../components/Title";
import { Link } from "react-router-dom";

const Offerte = () => {
  return (
    <div className="offerte">
      <Title title="offerte" />
      <div className="main">
        <p className="thick">
          Indien u door TAXUS Timmerwerken een offerte wilt laten opmaken, kunt
          u vrijblijvend contact opnemen. TAXUS Timmerwerken komt graag bij u
          langs om samen de plannen en mogelijkheden door te nemen. Zo kan TAXUS
          Timmerwerken een gespecificeerde offerte opmaken welke precies aan uw
          eisen voldoet.
        </p>
        <h2 className="blueH2">TAXUS Timmerwerken</h2>
        <ul>
          <li>Klein Heidestein 2c</li>
          <li>3971 ND Driebergen Rijsenburg</li>
          <li>030 274 6824</li>
          <li>
            <Link to="mailto:info@taxustimmerwerken.nl">
              info@taxustimmerwerken.nl
            </Link>
          </li>
          <li>
            <Link to="https://www.taxustimmerwerken.nl">
              www.taxustimmerwerken.nl
            </Link>
          </li>
          <li>KvK-nummer: 27368556</li>
          <li>BTW-nummer: NL 001939239B35</li>
        </ul>
      </div>
    </div>
  );
};

export default Offerte;
