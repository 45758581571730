import React from "react";
import Title from "../components/Title";
import { Link } from "react-router-dom";

const Offerte = () => {
  return (
    <div className="offerte">
      <Title title="Quotation" />
      <div className="main">
        <p className="thick">
          If you want Taxus Timmerwerken a quotation, you can Contact you
          without obligation. Taxus Timmerwerken is happy to come to you Along
          to go through the plans and possibilities together. Taxus can
          Carpentry a specified quotation that exactly requirements.
        </p>
        <h2 className="blueH2">TAXUS Timmerwerken</h2>
        <ul>
          <li>Klein Heidestein 2c</li>
          <li>3971 ND Driebergen Rijsenburg</li>
          <li>030 274 6824</li>
          <li>
            <Link to="mailto:info@taxustimmerwerken.nl">
              info@taxustimmerwerken.nl
            </Link>
          </li>
          <li>
            <Link to="https://www.taxustimmerwerken.nl">
              www.taxustimmerwerken.nl
            </Link>
          </li>
          <li>KvK-number: 27368556</li>
          <li>BTW-number: NL 001939239B35</li>
        </ul>
      </div>
    </div>
  );
};

export default Offerte;
