import React from "react";
import Title from "../components/Title";
import { Link } from "react-router-dom";

const Projecten = () => {
  const images1 = ["1", "1.2", "1.3", "1.4", "1.5", "1.6", "1.7", "1.8", "1.9"];
  const images2 = ["2", "2.2", "2.3", "2.4", "2.5", "2.6", "2.7", "2.8"];
  const images3 = ["3"];
  const images4 = ["4", "4.2", "4.3"];
  const images5 = ["5", "5.2", "5.3", "5.4", "5.5", "5.6", "5.7"];
  const images6 = ["6", "6.2", "6.3", "6.4", "6.5", "6.6", "6.7", "6.8"];
  const images7 = ["7", "7.2", "7.3", "7.4", "7.5", "7.6", "7.7", "7.8"];
  const images8 = ["8", "8.2", "8.3", "8.4", "8.5", "8.6", "8.7", "8.8", "8.9"];
  const images9 = ["9", "9.2", "9.3"];
  const images10 = ["10", "10.2", "10.3"];

  return (
    <div className="projecten">
      <Title title="Projects" />
      <div className="main projecten">
        <div class="grid">
          <Link
            state={{ images: images1 }}
            to="/closeups?name=Rustic oak stairs"
          >
            <div class="items">
              <span class="shadow"></span>
              <img
                src="../img/1.jpg"
                alt="build project"
                title="Rustic oak stairs"
              />
            </div>
          </Link>
          <Link
            state={{ images: images2 }}
            to="/closeups?name=Cupboard wall living room"
          >
            <div class="items">
              <span class="shadow"></span>
              <img
                src="../img/2.jpg"
                alt="build project"
                title="Cupboard wall living room"
              />
            </div>
          </Link>
          <Link
            state={{ images: images3 }}
            to="/closeups?name=outdoor staircase with balcony and deck"
          >
            <div class="items">
              <span class="shadow"></span>
              <img
                src="../img/3.jpg"
                alt="build project"
                title="Outdoor staircase with balcony and deck"
              />
            </div>
          </Link>
          <Link
            state={{ images: images4 }}
            to="/closeups?name=garden room / shed"
          >
            <div class="items">
              <span class="shadow"></span>
              <img
                src="../img/4.jpg"
                alt="build project"
                title="Garden room / shed"
              />
            </div>
          </Link>
          <Link
            state={{ images: images5 }}
            to="/closeups?name=Tailor -made cupboard"
          >
            <div class="items">
              <span class="shadow"></span>
              <img
                src="../img/5.jpg"
                alt="build project"
                title="Tailor -made cupboard"
              />
            </div>
          </Link>
          <Link
            state={{ images: images6 }}
            to="/closeups?name=Expansion conservatory Farm"
          >
            <div class="items">
              <span class="shadow"></span>
              <img
                src="../img/6.jpg"
                alt="build project"
                title="Expansion conservatory Farm"
              />
            </div>
          </Link>
          <Link
            state={{ images: images7 }}
            to="/closeups?name=Customized kitchen"
          >
            <div class="items">
              <span class="shadow"></span>
              <img
                src="../img/7.jpg"
                alt="build project"
                title="Customized kitchen"
              />
            </div>
          </Link>
          <Link state={{ images: images8 }} to="/closeups?name=Renovation Farm">
            <div class="items">
              <span class="shadow"></span>
              <img
                src="../img/8.jpg"
                alt="build project"
                title="Renovation Farm"
              />
            </div>
          </Link>
          <Link state={{ images: images9 }} to="/closeups?name= bar furniture">
            <div class="items">
              <span class="shadow"></span>
              <img
                src="../img/9.jpg"
                alt="build project"
                title="Bar furniture"
              />
            </div>
          </Link>
          <Link
            state={{ images: images10 }}
            to="/closeups?name=Renovation kitchen"
          >
            <div class="items">
              <span class="shadow"></span>
              <img
                src="../img/10.jpg"
                alt="build project"
                title="Renovation kitchen"
              />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Projecten;
