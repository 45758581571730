import React from "react";
import Title from "../components/Title";

const Faq = () => {
  return (
    <div className="faq">
      <Title title="FAQ" />
      <div className="main">
        <h2>
          Krijg antwoord op de door onze klanten en opdrachtgevers meest
          gestelde vragen.
        </h2>
        <h2 className="blueH2">Worden er voorrijkosten berekend?</h2>
        <p>Nee. TAXUS Timmerwerken rekent geen voorrijkosten.</p>
        <h2 className="blueH2">
          Zijn er kosten aan verbonden aan een offerte?
        </h2>
        <p>
          Nee. TAXUS Timmerwerken komt graag langs om uw wensen en mogelijkheden
          te bespreken en vervolgens een offerte voor u uit te brengen.
        </p>
        <h2 className="blueH2">Wat houd de garantie in?</h2>
        <p>
          <var>~ bijlage algemene garantieverklaring</var>
        </p>
        <h2 className="blueH2">Hoe kan ik een referentie geven?</h2>
        <p>
          Na het afronden van het project kunt u een referentie sturen naar{" "}
          <a href="mailto:referentie@taxustimmerwerken.nl.">
            referentie@taxustimmerwerken.nl.
          </a>
        </p>
        <h2 className="blueH2">Hoe zijn ik en TAXUS Timmerwerken verzekerd?</h2>
        <p>
          TAXUS Timmerwerken is in het bezit van een
          aansprakelijkheidsverzekering en bij constructieve zaken ook een
          c.a.r. verzekering (Construction All Risks).
        </p>
        <h2 className="blueH2">
          Heeft u een andere vraag? Neemt u dan gerust contact met ons op.
        </h2>
        <p>
          <strong>Telefoon:</strong> 030-2746824
        </p>
        <br />
        <p>
          <strong>E-mail:</strong>{" "}
          <a href="mailto:klantenservice@taxustimmerwerken.nl">
            klantenservice@taxustimmerwerken.nl
          </a>
        </p>
      </div>
    </div>
  );
};

export default Faq;
