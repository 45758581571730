import { useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

const Page = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 5000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  return (
    <>
      <div className="home">
        <div className="homeCont">
          <div className="introduction introductionXL">
            <h1>passie voor bouwen</h1>
          </div>
          <div className="mainHome">
            <div className="title">
              <h2>Ontdek TAXUS Timmerwerken</h2>
              <h3>Graag leggen wij u onze krachten uit</h3>
            </div>
            <div ref={sliderRef} className="keen-slider">
              <div className="keen-slider__slide number-slide1">
                <div className="slideCont">
                  <img src="../img/card1.png" alt="" />
                  <div className="rightCard">
                    <h2>Snelheid &amp; Flexibiliteit</h2>
                    <p>
                      TAXUS Timmerwerken is zich ervan bewust dat een verbouwing
                      in of rond het huis een grote inbreuk is op de privacy van
                      de bewoners. Het is prettig als de werkzaamheden...
                    </p>
                    <a href="/verder?name=Snelheid en Flexibiliteit">
                      <button>Lees Verder</button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="keen-slider__slide number-slide2">
                <div className="slideCont">
                  <img src="../img/card2.png" alt="" />
                  <div className="rightCard">
                    <h2>Professionaliteit</h2>
                    <p>
                      Doordat de oprichter van TAXUS Timmerwerken een bouwkundig
                      ingenieur is, is er geen gebrek aan kennis van bouwen.
                      Daarnaast heeft TAXUS Timmerwerken een goed en...
                    </p>
                    <a href="/verder?name=Professionaliteit">
                      <button>Lees Verder</button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="keen-slider__slide number-slide3">
                <div className="slideCont">
                  <img src="../img/card3.png" alt="" />
                  <div className="rightCard">
                    <h2>Persoonlijkheid</h2>
                    <p>
                      TAXUS Timmerwerken heeft een passie voor bouwen en
                      daarnaast wil TAXUS Timmerwerken dat het geleverde product
                      een uniek product is, specifiek voor de klant. Taxus...
                    </p>
                    <a href="/verder?name=Persoonlijkheid">
                      <button>Lees Verder</button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="keen-slider__slide number-slide4">
                <div className="slideCont">
                  <img src="../img/card4.png" alt="" />
                  <div className="rightCard">
                    <h2>Betrouwbaar</h2>
                    <p>
                      Betrouwbaar werk afleveren tegen eerlijke prijzen is voor
                      TAXUS Timmerwerken vanzelfsprekend. Wij hebben een passie
                      voor bouwen en leveren geen half werk. Daarnaast...
                    </p>
                    <a href="/verder?name=Betrouwbaar">
                      <button>Lees Verder</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {loaded && instanceRef.current && (
              <div className="dots">
                {[
                  ...Array(
                    instanceRef.current.track.details.slides.length
                  ).keys(),
                ].map((idx) => {
                  return (
                    <button
                      key={idx}
                      onClick={() => {
                        instanceRef.current?.moveToIdx(idx);
                      }}
                      className={
                        "dot" + (currentSlide === idx ? " active" : "")
                      }
                    ></button>
                  );
                })}
              </div>
            )}
          </div>
          <div className="introduction introductionXL">
            <h1>passie voor bouwen</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;
