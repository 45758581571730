import React, { useState } from "react";
// import { Link } from "react-router-dom";

const Footer = () => {
  const currYear = new Date().getFullYear();

  const [diensten, setDiensten] = useState("");
  const [over, setOver] = useState("");

  const handleCurrent = (e) => {
    setDiensten("");
    setOver("");
    e("current");
    window.scrollTo(0, 0);
  };
  return (
    <div className="footer">
      <div className="txtWrapper">
        <div className="txt">
          <h3>Who are we?</h3>
          <p>
            Taxus Timmerwerken was founded on January 1, 2010 by Jouke Rypkema.
            Jouke Rypkema is after his MTS and HTS Architecture Training having
            successfully completed, his working career in 2002 started. He
            worked at a nationwide assembly company as Calculator and work
            planner and quickly worked as successful Project Manager. During
            this job Jouke has on Zambia on voluntary base built a school under
            very primitive circumstances.
          </p>
          <a
            href="/about"
            onClick={() => {
              handleCurrent(setOver);
            }}
          >
            <button>read more...</button>
          </a>
        </div>
        <div className="txt">
          <h3>What do we do?</h3>
          <p>Taxus Timmerwerken has four specializations.</p>
          <ul>
            <li>Build</li>
            <li>Conversion</li>
            <li>Renovation</li>
            <li>Restoration</li>
          </ul>
          <a
            href="/services"
            onClick={() => {
              handleCurrent(setDiensten);
            }}
          >
            <button>Want to know more? click here</button>
          </a>
        </div>
      </div>
      <div className="copyright">
        <p>
          Copyright &copy; 2010-{currYear} TAXUS Timmerwerken - All rights
          reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
