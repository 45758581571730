import React from "react";
import Title from "../components/Title";

const Referenties = () => {
  return (
    <div className="referenties">
      <Title title="referenties" />
      <div className="main">
        <h2 className="blueH2">Mooi resultaat</h2>
        <p>
          Onlangs heeft u werkzaamheden voor ons uitgevoerd, een kelder trap
          weer veilig gemaakt en een houten pui vervangen waar houtrot toe had
          geslaan.
          <br />
          <br />
          Alles is uiterst professioneel en volgens afspraak uitgevoerd en wij
          zijn helemaal tevreden.
          <br />
          Wij zullen u zeker nog een keer benaderen.
          <br />
          <br />
          Met vriendelijke groet,
          <br />
          <br />
          Chris en Carol
        </p>

        <h2 className="blueH2">Bedankt voor je werk!</h2>
        <p>
          Taxus Timmerwerken heeft bij mij in de berging kasten gemonteerd en
          mijn bedrijfswagen ingericht. En dat alles met 100 % tevredenheid. Ik
          ben zeer tevreden van het werk dat Taxus Timmerwerken geleverd heeft.
          Ik raad Taxus Timmerwerken iedereen aan. Bedankt Jouke. Als ik weer
          zoiets heb, dan bel ik je zeker.
          <br />
          <br />
          Groeten,
          <br />
          <br />
          Duran
        </p>

        <h2 className="blueH2">Mooie garage geworden</h2>
        <p>
          We zijn heel erg tevreden over de manier waarop Jouke gewerkt heeft om
          een nieuwe houten garage te bouwen. Alles ging precies volgens
          planning en afspraken. Jouke werkte heel hard door en liet alles
          steeds netjes achter. Hij dacht creatief mee, zoals bij het hergebr
          /uik van garagedeuren. We zijn erg blij met het resultaat.
          <br />
          <br />
          Felice en Arie
        </p>

        <h2 className="blueH2">Mooi werk</h2>
        <p class="noLine">
          Taxus Timmerwerken is voor ons:
          <br />
          <br />
        </p>
        <ul>
          <li>Heldere en eerlijke offertes.</li>
          <li>
            Een bedrijf dat meedenkt en niet louter uitvoert zonder na te
            denken.
          </li>
          <li>Een bedrijf dat afspraken nakomt.</li>
          <li>
            Een bedrijf dat rustig en schoon werkt en rekening houdt met een
            druk gezin met jonge kinderen.
          </li>
        </ul>
        <p>
          <br />
          <br />
          Daarom staan wij ervoor open om potentiële klanten van Taxus
          Timmerwerken te woord te staan en zelfs het uitgevoerde werk bij ons
          in huis te tonen.
          <br />
          <br />
          Was getekend Sjoerd
        </p>

        <h2 className="blueH2">Een echte vakman!</h2>
        <p>
          Jouke is een echte vakman en een prettig persoon die meedenkt, met
          duidelijke voorstellen komt en met wie we goed afspraken hebben kunnen
          maken. Hij heeft voor ons een prachtige verhoogde hardhouten
          terrasvlonder ontworpen en gemaakt, met gedeeltelijke overkapping en
          berging daaronder. Daarnaast ook een hardhouten tuinbank. Jouke is een
          harde werker die pas tevreden is als het helemaal goed is en biedt een
          prima prijs-kwaliteit verhouding.
          <br />
          <br />
          Kortom: wij zouden hem zeker opnieuw inschakelen en raden hem iedereen
          aan!
          <br />
          <br />
          Peter en Marijke
        </p>

        <h2 className="blueH2">Professioneel bedrijf</h2>
        <p>
          Taxus heeft voor ons een aantal opdrachten uitgevoerd (oa. deur
          omgehangen en fietsenstalling gebouwd).
          <br />
          <br />
          Deze zijn zeer professioneel, binnen budget én tijdsplanning
          uitgevoerd.
          <br />
          Jouke heeft aangetoond verstand van zaken te hebben en plezier in het
          vak te hebben.
          <br />
          Ik kan Taxus Timmerwerken en Jouke Rypkema iedereen aanbevelen.
          <br />
          <br />
          Roderic
        </p>

        <h2 className="blueH2">Een plezier om mee samen te werken</h2>
        <p>
          Jouke heeft ons geholpen bij de transformatie van een oude bouwkeet
          tot onze pipowagen.
          <br />
          <br />
          Hij blijft altijd rustig, is zeer terzake kundig, staat open voor
          suggesties, kan indien nodig goed improviseren om de juiste oplossing
          te vinden en is een harde werker.
          <br />
          <br />
          Het was een plezier om met hem samen te werken.
          <br />
          <br />
          Frans en Anne-Mieke
        </p>

        <h2 className="blueH2">Prachtige schuur annex buitenverblijf</h2>
        <p>
          We hebben Jouke in de hand genomen omdat we vanaf de eerste ontmoeting
          een goede klik hadden. Zijn liefde voor het ambacht straalt er vanaf.
          Binnen de ingeschatte tijd en binnen budget heeft hij de klus perfect
          opgeleverd. Hij draagt zorg voor zijn spullen en voor zijn omgeving
          daardoor is het lekker werken met hem. Inmiddels heeft Jouke een
          vervolg opdracht al weer net zo mooi opgeleverd. Wat ons betreft een
          gouden relatie waar we hopelijk nog vaker gebr /uik van mogen maken.
          <br />
          <br />
          Wij genieten ondertussen van een prachtige schuur annex buitenverblijf
          in de tuin!
          <br />
          <br />
          Michiel & Carien
        </p>

        <h2 className="blueH2">
          Wij zijn zeer te spreken over de kwaliteit van TAXUS Timmerwerken
        </h2>
        <p>
          Jouke heeft onze gehele eerste verdieping geïsoleerd en van nieuwe
          wandjes en elektra voorzien. Daarnaast heeft hij een prachtige kast
          met paneeldeuren op maat gemaakt. Wij zijn zeer te spreken over de
          kwaliteit van Jouke zijn werk. Daarnaast is hij betrouwbaar, een goede
          planner en een goede communicator. We wisten bij Jouke precies waar we
          aan toe waren. Bovendien is Jouke een erg gezellige opgewekte kerel
          die je graag door je huis hebt lopen!
          <br />
          <br />
          Miriam en Dirk-Jan
        </p>

        <h2 className="blueH2">Zeer tevreden over het geleverde werk</h2>
        <p>
          Het na-isoleren van onze schuine daken hebben we laten doen door Taxus
          timmerwerken uit Zeist.
          <br />
          <br />
          Wij zijn zeer tevreden over het geleverde werk, met name de
          degelijkheid, accuratesse en de prettige omgang.
          <br />
          <br />
          Jenniskens
        </p>

        <h2 className="blueH2">Prachtige keuken geworden!</h2>
        <p>
          Wij hebben onze keukenwand laten bekleden met osb hout, om het een
          mooie industriële look te geven. Omdat wij in een huis wonen dat meer
          dan 100 jaar oud is, is alles scheef, een erg lastige klus om te
          bekleden. Jouke heeft alles perfect uitgevoerd, alles past precies en
          is prachtig afgewerkt. Hij is een echte vakman die heel goed weet wat
          hij doet. Hij neemt geen genoegen met details die niet kloppen, alles
          moet perfect zijn, vandaar ook de prachtige afwerking. Wij hebben hem
          met een zeer gerust hart achtergelaten in huis terwijl wij zelf aan
          het werk waren. Hij denkt in oplossingen, denkt mee, ook binnen een
          bepaald budget. Een betrouwbare vakman die wij zeker de volgende keer
          weer inschakelen als we iets moois willen laten maken in huis. Wij
          genieten nog elke dag van onze prachtige keuken!
          <br />
          <br />
          Ilvy en Sander
        </p>

        <h2 className="blueH2">Een echte vakman</h2>
        <p>
          Jouke is erin geslaagd alle scheve, kromme & piepende ramen en deuren
          van mijn jaren dertig huis weer in het gareel te krijgen. Waar ik zelf
          bang was dat ik een nieuwe achterdeur zou moeten aanschaffen, was dat
          a.g.v. een goed geplaatste driepuntssluiting niet eens nodig. De
          offerte was daarnaast in veel detail uitgewerkt zodat ik van te voren
          goed wist wat ik wel en niet kon verwachten. De opdracht is naar alle
          tevredenheid uitgevoerd!
          <br />
          <br />
          Sander
        </p>

        <h2 className="blueH2">Mooie zolder geworden!</h2>
        <p>
          Jouke heeft onze zolder verbouwd tot kamers met wanden, afwerking en
          elektra. De offerte is heel duidelijk opgesteld, je weet wat je krijgt
          en je kunt zien wat de kosten zijn als je voor verschillende opties
          kiest. Het werk is netjes en professioneel en gaat zoals je het
          vraagt. Daarnaast zijn alle afspraken nagekomen, wat ook prettig is
          bij dit soort klussen. En betrouwbare vakman.
          <br />
          <br />
          Niels
        </p>
      </div>
    </div>
  );
};

export default Referenties;
